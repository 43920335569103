.searchContainer {
    margin-bottom: 15px;
}

form .txtZip {
    margin-right: 10px;
    width: 50%;
}

form .selDistance {
    margin-right: 10px;
    /*min-width: 20ch;*/
}

form .btnSubmit {
    color: white;
    background-color: green;
}

@media screen and (max-width: 425px) {
    form .btnSubmit {
        margin-top: 10px;
    }
}
